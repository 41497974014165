<template>
  <v-data-table
    v-model="selectedRows"
    :headers="tableColumns"
    :items="availableSettlementsData"
    :items-per-page="5"
    item-key="settlementCode"
    show-select
  >
    <!-- UID -->
    <template #[`item.settlementCode`]="{ item }">
      <a href="#" @click="viewSettlement(item.awsKey)">{{ item.settlementCode }}</a>
    </template>

    <!-- Created At  -->
    <template #[`item.createdAt`]="{ item }">
      {{ formatDate(item.createdAt, { dateStyle: 'short' }) }}
    </template>

    <!-- Date From -->
    <template #[`item.startDate`]="{ item }">
      {{ formatDate(item.startDate, { dateStyle: 'short' }) }}
    </template>

    <!-- Date To -->
    <template #[`item.endDate`]="{ item }">
      {{ formatDate(item.endDate, { dateStyle: 'short' }) }}
    </template>

    <!-- Opening Balance -->
    <template #[`item.balanceToBeSettled`]="{ item }">
      {{ formatCurrency(item.balanceToBeSettled) }}
    </template>
  </v-data-table>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { formatDate, formatCurrency } from '@core/utils/filter'
import store from '@/store'

export default {
  inheritAttrs: false,
  props: {
    selection: {
      type: [Array, Object],
      default() {
        return []
      },
    },
    items: {
      type: [Array, Object],
      default() {
        return []
      },
    },
  },
  setup(props, { emit }) {
    const tableColumns = [
      { text: 'UID', value: 'settlementCode', sortable: false },
      {
        text: 'CREATED AT', value: 'createdAt', sortable: false, align: 'center',
      },
      {
        text: 'SHOP', value: 'shopName', sortable: false, align: 'center',
      },
      {
        text: 'FROM', value: 'startDate', sortable: false, align: 'center',
      },
      {
        text: 'TO', value: 'endDate', sortable: false, align: 'center',
      },
      {
        text: 'CURRENCY', value: 'currencyCode', sortable: false, align: 'center',
      },
      {
        text: 'AMOUNT', value: 'balanceToBeSettled', sortable: false, align: 'right',
      },
    ]

    const loading = ref(false)
    const selectedRows = ref([])
    const availableSettlementsData = ref([])

    const fetchAvailableSettlements = () => {
      loading.value = true

      store
        .dispatch('finance-withdrawals/fetchAvailableSettlements')
        .then(response => {
          availableSettlementsData.value = response.data
        })
        .catch(error => error).finally(() => {
          loading.value = false
        })
    }

    const viewSettlement = key => {
      store
        .dispatch('finance-withdrawals/getPresignedUrl', { key })
        .then(response => {
          const url = response.data
          window.open(url)
        })
        .catch(error => error).finally(() => {
          loading.value = false
        })
    }

    const fetchData = () => {
      fetchAvailableSettlements()
    }

    watch([selectedRows], () => {
      emit('update:selection', selectedRows.value)
    })

    watch([fetchAvailableSettlements], () => {
      emit('update:items', fetchAvailableSettlements.value)
    })

    return {
      tableColumns,
      formatDate,
      formatCurrency,
      selectedRows,
      fetchAvailableSettlements,
      availableSettlementsData,
      fetchData,
      viewSettlement,
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>
