var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.availableSettlementsData,"items-per-page":5,"item-key":"settlementCode","show-select":""},scopedSlots:_vm._u([{key:"item.settlementCode",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewSettlement(item.awsKey)}}},[_vm._v(_vm._s(item.settlementCode))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt, { dateStyle: 'short' }))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate, { dateStyle: 'short' }))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endDate, { dateStyle: 'short' }))+" ")]}},{key:"item.balanceToBeSettled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.balanceToBeSettled))+" ")]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})}
var staticRenderFns = []

export { render, staticRenderFns }