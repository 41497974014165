<template>
  <v-navigation-drawer
    id="v-navigation-drawer"
    :value="isAddNewSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 800 : '100%'"
    app
    @input="val => $emit('update:is-add-new-sidebar-active', val)"
  >
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          A new Withdrawal will be created. <br />
          Are you sure?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            :disabled="loading"
            @click="isDialogVisible = false"
          >
            No
          </v-btn>

          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="onAgree"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar elevation="0">
      <v-toolbar-title>Create Withdrawal</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        @click="$emit('update:is-add-new-sidebar-active', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-form
      ref="form"
      v-model="valid"
      class="ml-2 mt-2 mr-2"
      @submit.prevent="showConfirmationDialog"
    >
      <v-card class="mt-5">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Withdrawal Details
            </div>

            <v-card-text>
              <v-autocomplete
                v-model="model.withdrawalTypeCode"
                label="Withdrawal Type"
                :items="withdrawalTypeFilterItems"
                item-text="text"
                item-value="value"
                :rules="[v => !!v || 'This field is required']"
                outlined
                dense
                hide-details="auto"
              >
              </v-autocomplete>
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-card class="mt-5">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Available Settlements:
            </div>
            <withdrawal-settlements-list
              ref="settlementListRef"
              :selection.sync="selectedSettlements"
              :items.sync="settlements"
            ></withdrawal-settlements-list>
          </v-list-item-content>
        </v-list-item>
        <v-card-title>
          Total Settlements: {{ formatCurrency(totalAmount) }} USD
        </v-card-title>
      </v-card>

      <v-card
        v-if="model.withdrawalTypeCode == withdrawalTypeLocal.BANK_TRANSFER"
        class="mt-5"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Bank Details
            </div>

            <v-card-text>
              <v-text-field
                v-model="model.beneficiaryName"
                :rules="[validators.required]"
                outlined
                dense
                label="Beneficiary Name"
                placeholder="Beneficiary Name"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="model.beneficiaryAddress"
                :rules="[validators.required]"
                outlined
                dense
                label="Beneficiary Address"
                placeholder="Beneficiary Address"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="model.bankName"
                :rules="[validators.required]"
                outlined
                dense
                label="Bank Name"
                placeholder="Bank Name"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="model.accountNumber"
                :rules="[validators.required]"
                outlined
                dense
                label="Account Number"
                placeholder="Account Number"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="model.swift"
                :rules="[validators.required]"
                outlined
                dense
                label="Switf"
                placeholder="Swift"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="model.bankAddress"
                :rules="[validators.required]"
                outlined
                dense
                label="Bank Address"
                placeholder="Bank Address"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-autocomplete
                v-model="model.accountCurrencyCode"
                :rules="[validators.required]"
                placeholder="Bank Account Currency"
                label="Bank Account Currency"
                :items="currencyFilterItems"
                item-text="text"
                item-value="value"
                outlined
                clearable
                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-card
        v-if="model.withdrawalTypeCode == withdrawalTypeLocal.CRYPTO"
        class="mt-5"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Crypto Details
            </div>

            <v-card-text>
              <v-autocomplete
                v-model="model.cryptoCurrencyCode"
                :rules="[validators.required]"
                placeholder="Currency"
                label="Currency"
                :items="cryptoCurrencyFilterItems"
                item-text="text"
                item-value="value"
                outlined
                clearable
                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-card-text>

            <v-card-text>
              <v-autocomplete
                v-model="model.cryptoNetworkId"
                :rules="[validators.required]"
                placeholder="Network"
                label="Network"
                :items="cryptoNetworkFilterItems"
                item-text="text"
                item-value="value"
                outlined
                clearable
                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="model.walletAddress"
                outlined
                dense
                label="Wallet Address"
                placeholder="Wallet Address"
                hide-details="auto"
              ></v-text-field>
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-btn
        color="primary"
        type="submit"
        class="mt-5 mb-10"
        :loading="loading"
        :disabled="loading || !valid || totalAmount == 0"
      >
        CREATE
      </v-btn>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCalendar } from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import {
  required, emailValidator, numbersValidator, decimalValidator,
} from '@core/utils/validation'
import { formatCurrency } from '@core/utils/filter'
import store from '@/store'
import withdrawalType from '@/constants/withdrawal-type'
import WithdrawalSettlementsList from './WithdrawalSettlementsList.vue'

export default {
  components: {
    WithdrawalSettlementsList,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const settlementListRef = ref()
    const settlements = ref([])
    const statusFilterItems = ref([])
    const withdrawalTypeFilterItems = ref([])
    const currencyFilterItems = ref([])
    const cryptoCurrencyFilterItems = ref([])
    const cryptoNetworkFilterItems = ref([])
    const selectedSettlements = ref([])
    const vtoast = ref(null)
    const model = ref({})
    const valid = ref(false)
    const form = ref(null)
    const loading = ref(false)
    const showApplyOnModalDate = ref(false)
    const withdrawalTypeLocal = ref(withdrawalType)

    const validate = () => {
      if (form !== null) {
        form.value.validate()
      }
    }

    const innitModel = () => {
      model.value = {}
      model.value.bankExchangeRate = 0
      model.value.gtwExchangeRate = 0
      model.value.gtwAmount = 0
      model.value.amount = 0
      selectedSettlements.value = []
      settlements.value = []
    }

    const resetForm = () => {
      form.value.reset()
    }

    const totalAmount = computed(() => {
      const total = selectedSettlements.value.reduce((sum, item) => sum + item.balanceToBeSettled, 0)

      return Number(total.toFixed(2))
    })

    const showConfirmationDialog = () => {
      if (valid.value) {
        isDialogVisible.value = true
      }
    }

    const onAgree = () => {
      if (valid.value) {
        loading.value = true

        model.value.amount = totalAmount.value

        model.value.settlementIds = selectedSettlements.value.reduce((acc, settlement) => {
          acc.push(settlement.id)

          return acc
        }, [])

        store
          .dispatch('finance-withdrawals/add', model.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-sidebar-active', false)
            resetForm()
            innitModel()
            emit('show-message', 'Withdrawal created...')
          })
          .catch(error => {
            emit('show-errors', error.response.data.errors)
          })
          .finally(() => {
            loading.value = false
            isDialogVisible.value = false
          })
      } else {
        validate()
      }
    }

    const fetchConfig = () => {
      store
        .dispatch('finance-withdrawals/fetchCreateConfig')
        .then(response => {
          const config = response.data
          currencyFilterItems.value = config.currencies
          withdrawalTypeFilterItems.value = config.withdrawalTypes
          cryptoCurrencyFilterItems.value = config.cryptoCurrencies
          cryptoNetworkFilterItems.value = config.cryptoNetworks
          statusFilterItems.value = config.statusTypes
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchConfig()
      innitModel()
      settlementListRef.value?.fetchData()
    })

    return {
      vtoast,
      withdrawalTypeLocal,
      isDialogVisible,
      form,
      currencyFilterItems,
      statusFilterItems,
      withdrawalTypeFilterItems,
      cryptoCurrencyFilterItems,
      cryptoNetworkFilterItems,
      loading,
      valid,
      settlementListRef,
      settlements,
      selectedSettlements,
      model,
      showApplyOnModalDate,
      totalAmount,
      innitModel,
      validate,
      fetchConfig,
      showConfirmationDialog,
      onAgree,
      formatCurrency,

      // validation
      validators: {
        required,
        emailValidator,
        numbersValidator,
        decimalValidator,
      },
      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang='scss' scoped>
.align-center {
  display: table-cell;
}
</style>
