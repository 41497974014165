import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchListConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/withdrawals/page-setups/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCreateConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/withdrawals/page-setups/create')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/withdrawals', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/finance/withdrawals/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRelatedSettlements(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/finance/withdrawals/${id}/related-settlements`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/finance/withdrawals', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/finance/withdrawals/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/finance/withdrawals/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    markAsCancelled(ctx, { withdrawalCode, comments }) {
      const url = `/finance/withdrawals/${withdrawalCode}/cancelled`

      return new Promise((resolve, reject) => {
        axios
          .put(url, comments)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAvailableSettlements(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/finance/withdrawals/available-settlements')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPresignedUrl(ctx, { key }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/finance/settlements/presigned-url?key=${key}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
