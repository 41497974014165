<template>
  <div>
    <v-dialog
      v-model="showChangeStatusDialog"
      persistent
      width="500"
    >
      <v-form v-model="valid">
        <v-card>
          <v-card-title class="headline">
            Mark Withdrawal as {{ model.statusCode }}
          </v-card-title>
          <v-card-text> Would you like to mark this withdrawal as {{ model.statusCode }}? </v-card-text>

          <v-card-text>
            <v-textarea
              v-model="model.comments"
              :rules="[validators.required]"
              outlined
              label="Comments"
              placeholder="Comments"
              hide-details="auto"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              :loading="loading"
              :disabled="loading"
              @click="showChangeStatusDialog = false"
            >
              No
            </v-btn>
            <v-btn
              color="success"
              :loading="loading"
              :disabled="loading || !valid"
              @click="onChangeStatusAccepted"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- app drawer -->
    <withdrawal-list-add-new
      v-model="isAddNewSidebarActive"
      @refetch-data="fetchList"
      @show-message="onShowMessage"
      @show-errors="onShowErrors"
    ></withdrawal-list-add-new>

    <!-- list filters -->
    <v-card>
      <v-card-title>Withdrawals </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- Dates filter-->
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            ref="menuDateRangeFilter"
            v-model="menuDateRange"
            class="mt-0"
            :return-value.sync="datesFilter"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Dates Range"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="datesFilter"
              range
              no-title
              scrollable
            >
              <v-btn
                block
                outlined
                small
                @click="$refs.menuDateRangeFilter.save(datesFilter)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- merchant filter -->
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="shopFilter"
            placeholder="Select Shop"
            :items="shopFilterData"
            item-text="text"
            item-value="value"
            class="mt-2"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="withdrawalStatusFilterData"
            item-text="text"
            item-value="value"
            class="mt-2"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <!-- Buttons -->
        <v-col cols="12">
          <v-btn
            ref="btnApplyFilters"
            color="primary"
            class="me-3 mt-0"
            @click.prevent="applyFilters"
          >
            Apply Filters
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-0"
            type="reset"
            @click.prevent="resetFilters"
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewSidebarActive = !isAddNewSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Create New Withdrawal</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        ref="withdrawalTable"
        :headers="tableColumns"
        :items="gridRowsData"
        :search="searchQuery"
        :options.sync="options"
        :server-items-length="gridRowsCount"
        :items-per-page="10"
        :loading="loading"
      >
        <!-- UID -->
        <template #[`item.withdrawalCode`]="{ item }">
          <router-link
            class="mr-3"
            :to="{
              name: 'finance-withdrawal-view',
              params: { id: item.id },
            }"
          >
            {{ item.withdrawalCode }}
          </router-link>
        </template>

        <!-- Created At  -->
        <template #[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt, { dateStyle: 'short' }) }}
        </template>

        <!-- Created At  -->
        <template #[`item.paymentDate`]="{ item }">
          {{ item.paymentDate !== null ? formatDate(item.paymentDate, { dateStyle: 'short' }) : '' }}
        </template>

        <!-- Amount -->
        <template #[`item.amount`]="{ item }">
          {{ formatCurrency(item.amount) }}
        </template>

        <!-- Status -->
        <template #[`item.statusCode`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.statusCode)"
            :class="`${resolveStatusVariant(item.statusCode)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.statusCode }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-toast ref="vtoast"></v-toast>
  </div>
</template>

<script>
import { formatDate, formatCurrency } from '@core/utils/filter'
import {
  mdiExportVariant,
  mdiPlus,
  mdiCalendar,
  mdiBankOff,
  mdiBankTransferOut,
  mdiBankTransferIn,
  mdiAlertCircle,
} from '@mdi/js'
import {
  ref, watch, computed, onMounted, onUnmounted,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'
import store from '@/store'
import withdrawalStatus from '@/constants/withdrawal-status'
import VToast from '@/components/VToast.vue'
import withdrawalListAddNew from './WithdrawalAdd.vue'
import withdrawalStoreModule from './storeModule'

export default {
  components: {
    withdrawalListAddNew,
    VToast,
  },
  setup() {
    const STORE_MODULE_NAME = 'finance-withdrawals'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, withdrawalStoreModule)
    }

    const vtoast = ref(null)
    const showChangeStatusDialog = ref(false)
    const selectedWithdrawal = ref({})
    const model = ref({})
    const gridRowsData = ref([])
    const valid = ref(false)

    const tableColumns = [
      { text: 'UID', value: 'withdrawalCode', sortable: false },
      {
        text: 'WITHDRAWAL TYPE', value: 'withdrawalTypeCode', sortable: false, align: 'center',
      },
      {
        text: 'CREATED AT', value: 'createdAt', sortable: false, align: 'center',
      },
      {
        text: 'PAYMENT DATE', value: 'paymentDate', sortable: false, align: 'center',
      },
      {
        text: 'AMOUNT', value: 'amount', sortable: false, align: 'right',
      },
      {
        text: 'CURRENCY', value: 'currencyCode', sortable: false, align: 'center',
      },
      {
        text: 'STATUS', value: 'statusCode', sortable: false, align: 'center',
      },
    ]

    const searchQuery = ref('')
    const shopFilter = ref(null)
    const statusFilter = ref(null)
    const aMonthAgo = new Date()
    aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)
    const datesFilter = ref([aMonthAgo.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)])
    const datesRangeSelected = ref([])
    const shopFilterData = ref([])
    const withdrawalStatusFilterData = ref([])
    const gridRowsCount = ref(0)
    const loading = ref(false)

    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })

    const markAsCompletedDialog = ref(false)
    const pageNumber = ref(1)
    const pageSize = ref(10)
    const isAddNewSidebarActive = ref(false)
    const menuDateRange = ref(false)
    const dateRangeText = computed(() => datesFilter.value.join(' ~ '))

    const fetchList = () => {
      loading.value = true

      store
        .dispatch('finance-withdrawals/fetchList', {
          q: searchQuery.value,
          options: options.value,
          statusCode: statusFilter.value,
          shopId: shopFilter.value,
          pageNumber: pageNumber.value,
          pageSize: pageSize.value,
          periodType: 'CUSTOM', // TODO: CUSTOM
          from: datesFilter.value[0],
          to: datesFilter.value[1],
        })
        .then(response => {
          const { records, count } = response.data

          gridRowsData.value = records
          gridRowsCount.value = count
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const changeWithdrawalStatus = () => {
      switch (model.value.statusCode) {
        case withdrawalStatus.COMPLETED:
          return store.dispatch('finance-withdrawals/markAsCompleted', {
            withdrawalCode: model.value.withdrawalCode,
            comments: model.value.comments,
          })
        case withdrawalStatus.CANCELLED:
          return store.dispatch('finance-withdrawals/markAsCancelled', {
            withdrawalCode: model.value.withdrawalCode,
            comments: model.value.comments,
          })
        case withdrawalStatus.PENDING:
          return store.dispatch('finance-withdrawals/markAsPending', {
            withdrawalCode: model.value.withdrawalCode,
            comments: model.value.comments,
          })
        default:
          return null
      }
    }

    watch([options], () => {
      pageNumber.value = options.value.page
      pageSize.value = options.value.itemsPerPage
      fetchList()
    })

    const updateGridRow = row => {
      gridRowsData.value.find(x => x.withdrawalCode === row.value.withdrawalCode).value = row.value
    }

    const applyFilters = () => {
      fetchList()
    }

    const resetFilters = () => {
      searchQuery.value = ''
      shopFilter.value = null
      statusFilter.value = null
      datesFilter.value = [aMonthAgo.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)]
      datesRangeSelected.value = []
      gridRowsCount.value = 0
      pageNumber.value = 1
    }

    const fetchConfig = () => {
      store
        .dispatch('finance-withdrawals/fetchListConfig')
        .then(response => {
          const config = response.data
          shopFilterData.value = config.shops
          withdrawalStatusFilterData.value = config.withdrawalStatus
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    const viewWithdrawal = code => {
      store
        .dispatch('finance-withdrawals/getPreSignedUrl', { code })
        .then(response => {
          const url = response.data
          window.open(url)
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const showConfirmationDialog = (item, status) => {
      showChangeStatusDialog.value = true
      selectedWithdrawal.value = item
      model.value.statusCode = status
      model.value.withdrawalCode = item.withdrawalCode
    }

    const onChangeStatusAccepted = () => {
      changeWithdrawalStatus()
        .then(() => {
          vtoast.value.show(
            [
              {
                message: `The withdrawal: ${selectedWithdrawal.value.withdrawalCode} has been marked as ${model.value.statusCode}.`,
                code: '000',
              },
            ],
            'info',
          )
          selectedWithdrawal.value.statusCode = model.value.statusCode
          updateGridRow(selectedWithdrawal)
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')

          return error
        })
        .finally(() => {
          loading.value = false
          showChangeStatusDialog.value = false
        })
    }

    const toast = data => {
      vtoast.value.show(data.messages, data.type)
    }

    const onShowMessage = message => {
      vtoast.value.show([{ message, code: '000' }], 'info')
    }

    const onShowErrors = errors => {
      vtoast.value.show(errors, 'error')
    }

    onMounted(() => {
      fetchConfig()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*
    const resolveStatusVariant = status => {
      if (status === withdrawalStatus.PENDING) return 'secondary'
      if (status === withdrawalStatus.COMPLETED) return 'primary'
      if (status === withdrawalStatus.CANCELLED) return 'warning'

      return 'primary'
    }

    return {
      vtoast,
      valid,
      markAsCompletedDialog,
      showChangeStatusDialog,
      gridRowsData,
      tableColumns,
      searchQuery,
      shopFilter,
      shopFilterData,
      statusFilter,
      withdrawalStatus,
      withdrawalStatusFilterData,
      datesFilter,
      datesRangeSelected,
      menuDateRange,
      isAddNewSidebarActive,
      gridRowsCount,
      loading,
      options,
      dateRangeText,
      model,
      toast,
      onShowErrors,
      onShowMessage,
      onChangeStatusAccepted,
      showConfirmationDialog,
      applyFilters,
      resetFilters,
      fetch,
      fetchList,
      viewWithdrawal,
      fetchConfig,
      updateGridRow,
      resolveStatusVariant,
      changeWithdrawalStatus,
      formatDate,
      formatCurrency,

      validators: {
        required,
      },
      icons: {
        mdiPlus,
        mdiExportVariant,
        mdiCalendar,
        mdiBankOff,
        mdiBankTransferOut,
        mdiBankTransferIn,
        mdiAlertCircle,
      },
    }
  },
}
</script>
